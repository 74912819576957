.text-valera {
  font-family: "Varela Round", sans-serif !important;
}

.text-light {
  color: #ECF0F5;
}

.bg-light {
  background-color: #ECF0F5;
}

.text-accent-6 {
  color: #EF2E3B;
}

.bg-accent-6 {
  background-color: #EF2E3B;
}

.text-white-semi50 {
  color: rgba(255, 255, 255, 0.5);
}

.q-btn-dropdown--split.listaction .q-btn-dropdown__arrow-container {
  border-left: 1px solid #c5cae9;
}

.gradient-duskfushia {
  background-color: #222d31;
  background-image: radial-gradient(at 0% 0%, #D42C5F, #0A2652);
}

.gradient-mesosphere {
  background-color: #001C70;
  background-image: radial-gradient(at 0% 0%, #2196f3, #0b3c86);
}