
.sidenav {
  height: 100%;

  &-clocks {
    > div {
      text-align: center;
      line-height: 13px;
    }

    strong {
      font-size: 10px;
      display: block;
      letter-spacing: .8px;
      color: rgba(255,255,255,.7)
    }

    span {
      font-size: 12px;
    }
  }
}
.sidenav-active {
  color: white;
  background: #F2C037;
}
