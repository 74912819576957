// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary   : #364950;
$secondary : #222d31;
$accent    : #f03a47;
$accent-6 : #EF2E3B;

$light     : #ECF0F5;
$dark      : #1B2227;

$positive  : #02C39A;
$negative  : #f03a47;
$info      : #3e6990;
$warning   : #f99d4d;
