@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form

.text-valera {
  font-family: 'Varela Round', sans-serif !important;
}

.text-light {
  color: $light;
}
.bg-light {
  background-color: $light;
}

.text-accent-6 {
  color: $accent-6;
}
.bg-accent-6 {
  background-color: $accent-6;
}

.text-white-semi50 {
  color: rgba(255,255,255,.5);
}

.q-btn-dropdown--split.listaction .q-btn-dropdown__arrow-container {
  border-left: 1px solid $indigo-2;
}

@import "gradients.scss";
